/* eslint-disable react/no-danger */
import Head from 'next/head'

const ChatBot = () => (
    <Head>
        <script
            dangerouslySetInnerHTML={{
                __html: `
                    (function(I,n,f,o,b,i,p){
                        I[b]=I[b]||function(){(I[b].q=I[b].q||[]).push(arguments)};
                        I[b].t=1*new Date();i=n.createElement(f);i.async=1;i.src=o;
                        p=n.getElementsByTagName(f)[0];p.parentNode.insertBefore(i,p)})
                        (window,document,'script','https://livechat.infobip.com/widget.js','liveChat');
                        liveChat('customise', {
                            'widget-button-position-x': '10px',
                            'widget-button-position-y': '120px',
                            'widget-position-x': '10px',
                            'widget-position-y': '180px',
                            });
                        liveChat('init', '5f786fad-e4a9-4cb8-ad98-e4cd22adddd9');
                        liveChat('show');
                    `
            }}
        />
    </Head>
)

export default ChatBot
